@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../../node_modules/animate.css/animate.min.css";

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

@import "./background.scss";
@import "./header.scss";
@import "./landing.scss";
@import "./marketing.scss";
@import "./cms.scss";
@import "./listening.scss";
@import "./info.scss";

.bordered {
  border: 1px solid red;
}

// .active {
//   p {
//     display: block;
//   }

//   h1 {
//     opacity: 1 !important;
//     font-weight: bold;
//     font-size: 2.3rem;
//     opacity: 1;
//   }
// }

section {
  top: 0;
  width: 100%;
  height: 90vh;
  transition: all 0.3s ease;
  &.background:first-of-type {
    position: fixed;
    top: 0;
    z-index: -1;
  }

  h1 {
    font-size: 2.3rem;
  }
}

.header {
  height: 0rem;
}

body {
  background: black;
  height: 100vh;
  overflow: hidden;

  .scroller {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    position: relative;
    top: 0;
    scroll-behavior: smooth;

    section {
      scroll-snap-align: start;
    }
  }
}

[animate] {
  opacity: 0;
}

// Mobile view
@media (max-width: 768px) {
  .background {
    #bg1 {
      background-image: url("../assets/bg1-mobile.png");
    }
    #bg2 {
      background-image: url("../assets/bg2-mobile.png");
    }
    #bg3 {
      background-image: url("../assets/bg3.jpg");
    }
    #bg4 {
      background-image: url("../assets/bg4-mobile.png");
    }
  }
  .landing {
    p {
      width: 80% !important;
    }
  }

  .marketing,
  .cms,
  .listening {
    justify-content: center !important;
  }

  .info {
    a {
      font-size: 1.4rem;
    }
  }

  .pitch {
    text-align: right;
    p {
      margin-left: auto;
      width: 100% !important;
    }
  }
}
