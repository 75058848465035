.background {
  position: relative;
  opacity: 0;
  transition: all 1s ease-in-out;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: all 1s ease-in-out;
  }

  .bg-visible {
    opacity: 1;
  }

  #bg1 {
    background-image: url("../assets/bg1.png");
  }
  #bg2 {
    background-image: url("../assets/bg2.png");
  }
  #bg3 {
    background-image: url("../assets/bg3.jpg");
  }
  #bg4 {
    background-image: url("../assets/bg4.png");
  }
  #info {
    background-image: url("../assets/info.png");
  }
}
