.landing {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .links {
    width: 60%;
    margin-left: auto;
    margin-right: 5%;

    .link {
      opacity: 0.3;
      text-align: right;
      margin-top: 1rem;
      transition: all 1s ease;

      a {
        opacity: 0.3;
        color: white;
        text-decoration: none;
        transition: all 0.3s ease;
        font-size: 1rem;
      }
      &:hover {
        opacity: 1;

        a {
          opacity: 1;
          font-size: 1.1rem;
        }
      }
    }
  }

  .brand {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    h1 {
      color: white;
      margin-bottom: 1rem;
    }

    p {
      padding-top: 1rem;
      width: 50%;
      min-width: 300px;
      color: rgba(255, 255, 255, 0.715);
      font-size: 1rem;
      border-top: 1px solid white;

      b {
        color: white;
      }
    }
  }
}
