.info {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 80%;
  margin-left: auto;
  margin-right: auto;

  a {
    text-decoration: none;
    color: white;
    font-size: 2rem;
    text-overflow: wrap;
    line-break: anywhere;
    opacity: 0.8;
  }
}
