header {
  color: white;
  width: 100%;
  padding: 2rem;
  font-size: 1.3rem;

  img {
    height: 1.3rem;
    width: auto;
  }
}
