.listening {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .pitch {
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    h1 {
      color: white;
      margin-bottom: 1rem;
    }

    p {
      padding-top: 1rem;
      width: 60%;
      min-width: 300px;
      color: rgba(255, 255, 255, 0.715);
      font-size: 1rem;
      border-top: 1px solid white;

      b {
        color: white;
      }
    }
  }
}
